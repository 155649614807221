<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1">
        <div class="content animate__animated animate__zoomIn">
          <div class="title1">行业资讯</div>
          <div class="title2">
            <p style="text-align: center; z-index: 1">
              <span style="letter-spacing: 10px; z-index: 1"
                ><span style="font-family: 'Arial Black'; z-index: 1"
                  ><span style="color: rgb(255, 255, 255); z-index: 1"><span style="font-size: 14px; z-index: 1">INDU</span></span
                  ><span style="color: rgb(0, 255, 192); z-index: 1"><span style="font-size: 14px; z-index: 1">S</span></span
                  ><span style="color: rgb(255, 255, 255); z-index: 1"><span style="font-size: 14px; z-index: 1">TRY INFOR</span></span
                  ><span style="color: rgb(0, 255, 192); z-index: 1"><span style="font-size: 14px; z-index: 1">M</span></span
                  ><span style="color: rgb(255, 255, 255); z-index: 1"><span style="font-size: 14px; z-index: 1">ATION</span></span></span
                ></span
              >
            </p>
          </div>
        </div>
      </div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content" style="margin-top: 70px;min-height:600px" v-loading="newsLoading">
          <template v-for="news in newsList">
            <div class="content_col" :key="news.id + '_1'">
              <div class="col_1" @click="newsInfo(news.id)"><el-link :href="'/news/info?id=' + news.id"><img :src="news.imageUrl" /></el-link></div>
              <div class="col_2">
                <div class="title" @click="newsInfo(news.id)">{{ news.title }}</div>
                <div class="summary">{{ news.summary }}</div>
                <div class="time">{{ news.createTime.substring(0, 10) }}</div>
              </div>
            </div>
            <hr class="line" :key="news.id + '_2'" />
          </template>
          <pagination class="pageInfo" small layout="prev, pager, next" v-show="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.pageSize" @pagination="queryPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import http from "@/api/front";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      newsLoading: true,
      total: 0,
      query: {
        page: 1,
        pageSize: 10,
      },
      newsList: [],
    };
  },
  mounted() {
    this.queryPage();
  },
  methods: {
    queryPage() {
      this.newsLoading = true;
      http.queryNewsInfoPage(this.query, (res) => {
        if (res.success) {
          this.total = res.data.count;
          this.newsList = res.data.records;
        }
        this.newsLoading = false;
      });
    },
    newsInfo(newId) {
      this.$router.push({
        path: "/news/info",
        query: {
          id: newId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "news.scss";
</style>
